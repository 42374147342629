import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toggleBKFModal } from '@State/bkf/actions';
import { isBookingNew } from '@Utils/booking-util';
import { bkfModals } from '@State/bkf/constants';
import { getInitialValuesFromRruleString, getRruleDescription } from './recurring-util';
import BookingRecurringForm from './booking-recurring-form';

const BookingRecurring = ({ id, recurrenceRule, onShowSeries }) => {
  const isNew = isBookingNew(id);
  const [editRule, setEditRule] = useState(isNew);

  const initialValues = useMemo(() => {
    return getInitialValuesFromRruleString(recurrenceRule);
  }, [recurrenceRule]);

  if (editRule) {
    return (
      <div className="booking-form-block form">
        <BookingRecurringForm initialValues={initialValues} />
        {!isNew && (
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={() => setEditRule(false)}>Stäng</button>
          </div>
        )}
      </div>
    );
  }

  if (recurrenceRule) {
    return (
      <div className="booking-form-block one-line" onClick={() => onShowSeries()}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          <button className="btn-label" tabIndex={-1} onClick={() => onShowSeries()}>
            Visa mer
          </button>
        </div>
        <div>
          <i className="fa fa-repeat mr1" />
          {getRruleDescription(recurrenceRule)}
        </div>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  const { bkf } = state;
  const { recurrenceRule } = bkf.get('bookingSeries') || {};

  return {
    id: bkf.get('id'),
    recurrenceRule
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowSeries: () => dispatch(toggleBKFModal(bkfModals.series, true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingRecurring);
