import React from 'react';
import moment from 'moment';
import { colors } from '@Components/ui/styled/variables';
import { viewDateToStartEnd } from './time-util';
import { CustomerType } from './customer-util';
import { calendar } from './preference-keys';
import { txt } from './i18n-util';
import msg from './booking-util.msg';

export const NEW_BOOKING = 'DRAGGER';
export const isBookingNew = (props) => {
  return props === NEW_BOOKING || props.id === NEW_BOOKING;
};
export const isBookingCancelled = (props) => {
  const { attributes, status } = props;
  return (attributes?.status || status) === 'Cancelled';
};

const isType = ({ attributes, type }, value) => (attributes?.type || type) === value;
export const isSimpleBooking = (props) => isType(props, bookingTypes.SimpleBooking);
export const isClassBooking = (props) => isType(props, bookingTypes.ClassBooking);
export const isBookingReservation = (props) => isType(props, bookingTypes.Reservation);
export const isScheduleException = (props) => isType(props, bookingTypes.ScheduleException);
export const isClassWithBookings = (props) => isClassBooking(props) && props.bookedSlots > 0;
export const isBookingRecurring = (props) => props.bookingSeries?.bookingIds.length > 1;

export function confirmMoveBooking(locationConfig, booking) {
  return locationConfig.get(calendar.confirmMoveEnabled)
    || booking.type === bookingTypes.ClassBooking;
}

export const bookingTypes = {
  SimpleBooking: 'SimpleBooking',
  ClassBooking: 'ClassBooking',
  Reservation: 'Reservation',
  ScheduleException: 'ScheduleException'
};

export const scheduleOpenTypes = {
  Normal: 'Normal',
  Overtime: 'Overtime'
};

export const scheduleTimeTypes = {
  Scheduled: 'Schemalagd tid',
  Journaled: 'Närvarotid',
  Overtime: 'Övertid',
  Qualifying: 'Karenstid',
  SickLeave80: 'Sjuk',
  SickChildLeave: 'VAB',
  Vacation: 'Semester',
  OnLeave: 'Tjänstledig',
  Furlough: 'Permission',
  WorkingHoursReduction: 'ATF',
  Absence: 'Frånvaro'
};

export function getDescription(description, services) {
  const hasServices = services && services.length > 0;
  const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';
  return description || serviceDescriptions;
}

export function getPrimaryCustomer(props) {
  return isSimpleBooking(props)
    ? props.customers?.find(c => c.customerType === CustomerType.Person)
    : null;
}

export function getVehicleCustomer(props) {
  return isSimpleBooking(props)
    ? props.customers?.find(c => c.customerType === CustomerType.Vehicle)
    : null;
}

export function getCompanyCustomer(props) {
  return isSimpleBooking(props)
    ? props.customers?.find(c => c.customerType === CustomerType.Company)
    : null;
}

export function getClassStatus(bookedSlots = 0, maxSlots = 0, classStatus = 'Open', seriesPublished = true) {
  if (!seriesPublished) {
    return {
      color: colors.darkGray40,
      description: txt(msg.classNotPublished),
      available: false
    };
  }
  if (classStatus === 'Closed') {
    return {
      color: colors.darkGray40,
      description: txt(msg.bookingClosed),
      available: false
    };
  }
  if (bookedSlots === maxSlots) {
    return {
      color: colors.danger,
      description: txt(msg.classFull),
      available: false
    };
  }
  if (bookedSlots / maxSlots >= 0.7) {
    return {
      color: colors.warning,
      description: txt(msg.classAlmostFull),
      available: true
    };
  }
  return {
    color: colors.statusGreen,
    description: txt(msg.bookingOpen),
    available: true
  };
}

export const paymentProviderIcon = (provider) => {
  switch (provider) {
    case 'Swish':
    case 'SwishMerchant':
      return <img src="/swish.svg" alt="Swish" className="icon" key={provider} />;
    case 'Klarna':
    case 'KlarnaPayments':
      return <img src="/klarna.svg" alt="Klarna" className="icon" key={provider} />;
    case 'Stripe':
    case 'Card':
    case 'ExternalCardReader':
      return <i className="fa fa-credit-card" key={provider} />;
    case 'Cash':
      return <i className="fa fa-coins" key={provider} />;
    case 'Voucher':
      return <i className="fa fa-gift" key={provider} />;
    case 'PrepaidBankPayment':
      return <i className="fa fa-money-bill-transfer" key={provider} />;
    case 'Invoice':
      return <i className="far fa-file-invoice" key={provider} />;
    case 'FortnoxInvoice':
      return <img src="/fortnox-icon.png" alt="Fortnox" className="icon" key={provider} />;
    default:
      return null;
  }
};

export const paymentStatusIcon = (paymentStatus) => {
  switch (paymentStatus) {
    case 'Unpaid':
      return 'fa fa-badge-dollar text-muted';
    case 'Paid':
      return 'fa fa-badge-dollar text-success';
    case 'Refunded':
      return 'fa fa-arrow-circle-left text-muted';
    case 'RefundFailed':
      return 'fa fa-exclamation-cirle text-danger';
    default:
      return 'fa fa-question-circle text-muted';
  }
};

export const invoiceStatusIcon = (invoiceStatus) => {
  switch (invoiceStatus) {
    case 'Paid':
      return 'fa fa-badge-dollar text-success';
    case 'Annulled':
      return 'fa fa-arrow-circle-left text-muted';
    case 'DebtCollection':
      return 'fa fa-exclamation-cirle text-danger';
    default:
      return 'fa fa-badge-dollar text-muted';
  }
};

export function isBookingInView(state, booking) {
  const resourceIds = state.resourcesById.keySeq().toArray();
  const { viewDate, viewMode } = state.calendarViewState.get('lastView');
  const { start, end } = viewDateToStartEnd(viewMode, viewDate);

  return booking.resources.every(r => resourceIds.includes(r.id))
    && moment(booking.startTime).isBetween(start, end, 'day', '[)');
}

function hasBookingOnSameDayInSeries(move, bookingsById) {
  const booking = bookingsById.get(move.id);
  return !!bookingsById.find(existing => {
    if (existing.id === move.id) {
      return false;
    }
    const isSameDay = moment(existing.startTime).isSame(move.startTime, 'day');
    const isSameSeries = existing.bookingSeries && booking.bookingSeries
      && existing.bookingSeries.seriesId === booking.bookingSeries.seriesId;

    return isSameDay && isSameSeries;
  });
}

export function validateMoveBooking(move, bookingsById, sourceResourceId, targetResourceId) {
  const booking = bookingsById.get(move.id);
  if (sourceResourceId !== targetResourceId && booking.resources.some(r => r.id === targetResourceId)) {
    console.error('Cannot move to existing resource on booking');
    return false;
  }
  if (hasBookingOnSameDayInSeries(move, bookingsById)) {
    console.error('Cannot move to same day as existing booking in series');
    return false;
  }
  return true;
}
