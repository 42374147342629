import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBKFModal } from '@State/bkf/actions';
import { getClassStatus } from '@Utils/booking-util';
import { bkfModals } from '@State/bkf/constants';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Loader from '@Components/ui/loader';
import { getRruleDescription } from './recurring-util';
import { useFetchBookingSeries } from './booking-form-series-hook';
import ChangeRecurrencePopover from './change-recurrence-popover';

const BookingSeriesModal = ({ routeParams }) => {
  const dispatch = useDispatch();
  const { loading, seriesId, recurrenceRule, bookings, published } = useFetchBookingSeries();
  const services = useSelector(state => state.bkf.get('services'));

  const onCloseForm = () => dispatch(toggleBKFModal(bkfModals.series, false));

  return (
    <ModalDialog
      focusTrapPaused
      title={services.map(s => s.name).join(', ')}
      closeOnClickOutside={false}
      onClose={onCloseForm}
      contentSize="xl"
      alignTop
    >
      {loading && <Loader />}
      {bookings && (
        <>
          <div>
            <i className="fa fa-repeat mr1" />
            {getRruleDescription(recurrenceRule)}
            <ChangeRecurrencePopover
              seriesId={seriesId}
              routeParams={routeParams}
              recurrenceRule={recurrenceRule}
            />
          </div>
          <table className="table mt4">
            <thead>
              <tr>
                <th>Tid</th>
                <th>Bokade</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => {
                const { bookingId, startTime, booked, maxSlots, classStatus } = booking;
                const status = getClassStatus(booked, maxSlots, classStatus, published);
                return (
                  <tr key={bookingId}>
                    <td>{moment(startTime).format('LLLL')}</td>
                    <td>{booked}/{maxSlots}</td>
                    <td>
                      <span className="color-dot" style={{ background: status.color }} />
                      {status.description}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </ModalDialog>
  );
};

export default BookingSeriesModal;
