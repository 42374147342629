import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getClipboardBannerStyle } from '@Utils/embedded-util';
import { notifyClipboardState } from '@Utils/wk-embed-bridges';

import Popover from '@Components/ui/popover';
import {
  addClipboardDragger,
  addPasteDragger,
  cancelCopyPaste,
  pasteBooking,
  removeClipboardDragger,
  removePasteDragger
} from '@State/clipboard-actions';
import { user } from '@Utils/preference-keys';
import { setUserPreference } from '@State/user-actions';
import { getGridProps } from '../grid/grid-selectors';
import ClipboardDragger from './clipboard-dragger';
import ClipboardItem from './clipboard-item';

class Clipboard extends Component {
  static propTypes = {
    clipboardBookings: PropTypes.object.isRequired,
    clipboardDragger: PropTypes.object,
    highContrast: PropTypes.bool.isRequired,
    gridProps: PropTypes.object.isRequired,
    hideHelpTipFromStart: PropTypes.bool.isRequired,
    onToggleHelpTip: PropTypes.func.isRequired,
    onCancelItem: PropTypes.func.isRequired,
    onAddChipDragger: PropTypes.func.isRequired,
    onRemoveChipDragger: PropTypes.func.isRequired,
    onAddClipboardDragger: PropTypes.func.isRequired,
    onRemoveClipboardDragger: PropTypes.func.isRequired,
    onPasteBooking: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      hideHelpTipChecked: props.hideHelpTipFromStart,
      showHelpTip: false
    };
  }

  render() {
    try {
      const { clipboardBannerStyle, clipboardBookings } = this.props;
      const { showHelpTip } = this.state;
      const showClipboardBanner = !clipboardBookings.isEmpty();

      return (
        <div id="clipboardbanner" style={clipboardBannerStyle}>
          <Popover className="helpTip" preferPlace="below" isOpen={showHelpTip} body={this.popoverContent()} onOuterAction={this.closePopover}>
            {showClipboardBanner ? this.clipboardContent() : <div />}
          </Popover>
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }

  popoverContent() {
    return (
      <div className="Popover-content">
        <a href="#" onClick={this.closePopover} className="Popover-close"><i className="far fa-lg fa-times" /></a>
        Här visas dina urklippta bokningar. Navigera till en ny dag eller vecka, dra och släpp sedan bokningen för att byta tid.
        <br />
        <a className="checkbox" href="#" onClick={this.toggleHelpTip}>
          {this.state.hideHelpTipChecked ? <i className="far fa-fw fa-check-square" /> : <i className="far fa-fw fa-square" />}
          Visa inte detta meddelande igen
        </a>
      </div>
    );
  }

  clipboardContent() {
    const {
      clipboardBookings, highContrast, gridProps, clipboardDragger
    } = this.props;
    const averageClipSize = gridProps.gridClientWidth / clipboardBookings.size;

    return (
      <div className="clipboard-container">
        {clipboardBookings.valueSeq().map((booking) => {
          return (
            <ClipboardItem
              key={booking.id}
              booking={booking}
              highContrast={highContrast}
              gridProps={gridProps}
              onClosePopover={this.closePopover}
              averageClipSize={averageClipSize}
              {...this.props}
            />
          );
        })}
        {clipboardDragger && (
        <ClipboardDragger
          ref={clipboardDragger.refCallback}
          highContrast={highContrast}
          gridProps={gridProps}
          initialLeft={clipboardDragger.initialLeft}
          initialTop={clipboardDragger.initialTop}
          label={clipboardDragger.label}
          type={clipboardDragger.type}
          status={clipboardDragger.status}
        />
        )}
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clipboardBookings, hideHelpTipFromStart } = this.props;
    const itemAddedToEmptyClipboard = clipboardBookings.isEmpty() && !nextProps.clipboardBookings.isEmpty();

    if (itemAddedToEmptyClipboard && !hideHelpTipFromStart) {
      this.setState({ showHelpTip: true });
    } else if (nextProps.clipboardBookings.isEmpty()) {
      this.setState({ showHelpTip: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clipboardBookings.isEmpty() !== this.props.clipboardBookings.isEmpty()) {
      this.props.updateViewDimensions();
      setTimeout(() => notifyClipboardState({ visible: !this.props.clipboardBookings.isEmpty() }), 1);
    }
  }

  closePopover = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ showHelpTip: false });
  };

  toggleHelpTip = (ev) => {
    ev.preventDefault();
    const newState = !this.state.hideHelpTipChecked;
    this.props.onToggleHelpTip(newState);
    this.setState({ hideHelpTipChecked: newState });
  };
}

const mapStateToProps = (state, ownProps) => {
  try {
    return {
      draggerVisible: state.gridViewState.get('draggerVisible'),
      clipboardBookings: state.bookingsClipboard,
      clipboardDragger: state.clipboardState.get('clipboardDragger'),
      highContrast: state.gridViewState.get('highContrast'),
      hideHelpTipFromStart: state.userClientPreferences.getIn(['user', user.hideClipboardHelpTip]) === true,
      gridProps: getGridProps(state, ownProps),
      clipboardBannerStyle: getClipboardBannerStyle(state)
    };
  } catch (e) {
    console.error(e);
  }
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onCancelItem: (bookingId) => {
      dispatch(cancelCopyPaste(bookingId));
    },

    onAddChipDragger: (dragger) => {
      dispatch(addPasteDragger(dragger, routeParams));
    },

    onRemoveChipDragger: () => {
      dispatch(removePasteDragger());
    },

    onAddClipboardDragger: (dragger) => {
      dispatch(addClipboardDragger(dragger));
    },

    onRemoveClipboardDragger: () => {
      dispatch(removeClipboardDragger());
    },

    onPasteBooking: (booking) => {
      return dispatch(pasteBooking(booking, routeParams));
    },

    onToggleHelpTip: (value) => {
      dispatch(setUserPreference(false, { [user.hideClipboardHelpTip]: value }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clipboard);
