import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ProgressButton from '@Components/ui/progress-button';
import ConfirmChangeSeries from './confirm-change-series';

const ConfirmResize = ({ bookingId, duration, onClosePopover, onConfirmResize, phoneMode }) => {
  const bookingsById = useSelector(state => state.bookingsById);
  const booking = bookingsById.get(bookingId);
  const series = booking?.bookingSeries;

  const [updateSeries, setUpdateSeries] = useState('Single');
  const className = phoneMode ? 'modal-body' : 'Popover-content';

  const handleConfirmResize = () => {
    return onConfirmResize({ updateSeries });
  };

  const handleUpdateSeriesChange = (ev) => {
    setUpdateSeries(ev.target.value);
  };

  return (
    <div className={className}>
      <div className="booking-form-block nopointer">
        <p>
          <strong>
            Vill du ändra bokningens längd till {duration} minuter?
          </strong>
        </p>

        {series && (
          <ConfirmChangeSeries
            action="resize"
            booking={booking}
            series={series}
            updateSeries={updateSeries}
            onChange={handleUpdateSeriesChange}
          />
        )}
      </div>

      <div className="text-right">
        <button type="button" className="btn btn-default" onClick={onClosePopover}>Avbryt</button> &nbsp;
        <ProgressButton small primary onClick={handleConfirmResize}>Ändra</ProgressButton>
      </div>
    </div>
  );
};

ConfirmResize.propTypes = {
  duration: PropTypes.number.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  onConfirmResize: PropTypes.func.isRequired
};

export default ConfirmResize;
