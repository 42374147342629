import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FocusTrap from 'focus-trap-react';
import moment from 'moment';
import s from 'underscore.string';
import { omit, pickAll } from 'ramda';
import diff from 'object-diff';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { renderTemplate } from '@Utils/handlebars';
import { getBkfServiceArray } from '@State/bkf/reducer';
import { NEW_BOOKING, confirmMoveBooking, getCompanyCustomer, getDescription, getPrimaryCustomer, getVehicleCustomer, isBookingNew, isClassBooking } from '@Utils/booking-util';
import { txt } from '@Utils/i18n-util';
import msg from '@Utils/booking-util.msg';

import Popover from '@Components/ui/popover';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { getServiceColors, getIsMultiResourceView, getResourceFromColIdx, getDimmedBookingIds } from '@State/calendar-selectors';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import { updateHighlightedBookings } from '@State/view-actions';
import { clearAndCloseBKF, loadBKFBooking, updateBKFCoords } from '@State/bkf/actions';
import { calcHeightFromMinutes, getDiffString } from '@Utils/time-util';
import { formatVehicleRegNo } from '@Utils/vehicle-util';
import { bookingMoved, getMoveEvent, moveBooking, removeTempBooking } from '@State/booking-actions';
import { calendar } from '@Utils/preference-keys';
import BookingForm from '@Components/calendar/booking/booking-form';
import BookingFormModal from '@Components/calendar/booking/booking-form-modal';
import { ChipDragHandler } from './chip-draghandler';
import { styles, getDynamicColorStyles, getServiceBlockStyles, getVisualStyles } from './chip-styles';
import { isWebBooking } from './chip-utils';
import ConfirmMove from './confirm-move';
import ConfirmResize from './confirm-resize';
import ChipIcons from './chip-icons';

const stylesPopover = { zIndex: 1000 };
const stylesVehicle = { opacity: 0.7, fontWeight: 'normal' };

class Chip extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    resizable: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    pendingMove: PropTypes.bool,
    note: PropTypes.string,
    reservation: PropTypes.bool.isRequired,
    channel: PropTypes.string.isRequired,
    reminders: PropTypes.array,
    customer: PropTypes.object,
    orgNo: PropTypes.string,
    orgName: PropTypes.string,
    vehicleRegNo: PropTypes.string,
    vehicleDescription: PropTypes.string,
    description: PropTypes.string,
    highContrast: PropTypes.bool.isRequired,
    gridProps: PropTypes.object.isRequired,
    colIdx: PropTypes.number.isRequired,
    confirmMoveEnabled: PropTypes.bool,
    features: PropTypes.object.isRequired,

    deviceType: PropTypes.string.isRequired,

    onChipMove: PropTypes.func.isRequired,
    onChipClick: PropTypes.func.isRequired,
    onChipResize: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  createState(props) {
    return {
      coords: props.initialCoords,
      startTime: props.startTime,
      endTime: props.endTime,
      isDragging: false,
      isResizing: false,
      dragChipTopDiff: this.state ? this.state.dragChipTopDiff : 0,
      resizeChipOriginalHeight: this.state ? this.state.resizeChipOriginalHeight : 0,
      showForm: this.shouldShowForm(props)
    };
  }

  shouldShowForm(props) {
    return !props.pasteDragger && props.showForm; // Dont show form if the chip is a paste booking
  }

  makeInitialState(props) {
    const initState = props.id === 'DRAGGER' ? null : {
      id: props.id,
      startTime: props.startTime,
      endTime: props.endTime,
      resourceId: props.resourceId,
      afterTime: props.afterTime
    };

    return { ...this.createState(props), initState };
  }

  componentDidUpdate(prevProps, prevState) {
    const resizableChanged = prevProps.resizable !== this.props.resizable;

    const timeChanged = !(this.state.endTime.isSame(prevState.endTime) && this.state.startTime.isSame(prevState.startTime));
    const colChanged = this.state.coords.colIdx !== prevState.coords.colIdx;

    // Only do this if the form is open for this chip
    //
    if (this.state.showForm && (timeChanged || colChanged)) {
      this.props.onChipStateChange({
        id: this.props.id,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        colIdx: this.state.coords.colIdx
      });
    }

    if (resizableChanged) {
      if (this.props.resizable) {
        this.draggable();
      } else {
        this.undraggable();
      }
    }
  }

  componentDidMount() {
    if (this.props.resizable) {
      this.draggable();
    }
  }

  componentWillUnmount() {
    this.undraggable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.chipDragHandler) {
      this.chipDragHandler.UNSAFE_componentWillReceiveProps(nextProps);
    }

    // Dont update state (and rerender chip coord) for certain prop updates
    //
    if (this.shouldIgnorePropUpdate(nextProps)) {
      return;
    }

    if (nextProps.showForm && !this.props.showForm) {
      this.popoverTrackerHeight = nextProps.initialCoords.height;
    }

    // Update init state for the chip if the form is closed, or was closed
    if (!nextProps.showForm) {
      this.setState(this.makeInitialState(nextProps));
    } else {
      this.setState(this.createState(nextProps));
    }
  }

  shouldIgnorePropUpdate(nextProps) {
    // Handle if state change is a flag change..
    //
    const p1 = omit(['initialCoords'], this.props);
    const p2 = omit(['initialCoords'], nextProps);
    const _diff = Object.keys(diff.custom({ equal: this.comparator }, p1, p2));

    return _diff.length === 1 && (_diff.includes('status') || _diff.includes('attributes'));
  }

  comparator(a, b) {
    if (a instanceof Function && b instanceof Function) {
      return true;
    }
    return a === b;
  }

  render() {
    const { deviceType, externalKeyboard, embeddedInApp } = this.props;
    const isMobile = deviceType === 'mobile';
    const isTablet = deviceType === 'tablet';

    if (embeddedInApp) {
      return this.chipWithoutWrappers();
    }

    const chip = isMobile || (isTablet && !externalKeyboard)
      ? this.chipWithFormModal()
      : this.chipWithFormPopoverV2();

    return this.shouldRenderWithConfirm()
      ? this.chipWithConfirm(chip, isMobile, this.confirmState())
      : chip;
  }

  shouldRenderWithConfirm() {
    return this.props.confirmMoveEnabled
      && (this.props.id !== 'DRAGGER' || this.props.pasteDragger);
  }

  chipWithConfirm(chip, isMobile, confirmState) {
    const confirmContent = confirmState.moved
      ? this.confirmMovePopoverContent()
      : this.confirmResizePopoverContent();

    if (isMobile) {
      return (
        <>
          {chip}
          {confirmState.showConfirm && (
            <ModalDialog
              noPadding
              focusTrapPaused
              closeOnClickOutside={false}
            >
              {confirmContent}
            </ModalDialog>
          )}
        </>
      );
    }

    return (
      <Popover
        body={confirmContent}
        isOpen={confirmState.showConfirm}
        onOuterAction={this.handleClosePopover}
      >
        {chip}
      </Popover>
    );
  }

  confirmState() {
    const resized = this.state.isResizing === false
            && this.state.isDragging === false
            && !this.state.endTime.isSame(this.props.endTime);

    const moved = this.state.isDragging === false
            && (!this.state.startTime.isSame(this.props.startTime) || this.props.colIdx !== this.state.coords.colIdx);

    // Dont show the confirm when the form is open
    return {
      resized,
      moved,
      showConfirm: !this.props.showForm && (resized || moved)
    };
  }

  chipWithoutWrappers() {
    return (this.chip());
  }

  chipWithFormModal() {
    const { showForm } = this.state;
    const {
      id, routeParams, customerFormVisible, showModal
    } = this.props;
    return (
      <>
        {this.chip()}
        {showForm && !customerFormVisible && !showModal && (
          <BookingFormModal
            id={id}
            routeParams={routeParams}
            onClose={this.handleClosePopover}
          />
        )}
      </>
    );
  }

  chipWithFormPopoverV2() {
    const popOverWidth = 380;
    const { showForm, coords } = this.state;
    const {
      id, routeParams, gridProps, customerFormVisible, showModal
    } = this.props;

    const w = gridProps.gridClientWidth - popOverWidth;
    const poTrackerWidth = coords.width > (gridProps.gridClientWidth / 2) ? w : '100%';
    const focusTrapPaused = customerFormVisible || !!showModal;

    const form = showForm && (
      <FocusTrap focusTrapOptions={{ initialFocus: '.Popover-booking' }} paused={focusTrapPaused}>
        <BookingForm id={id} routeParams={routeParams} onClose={this.handleClosePopover} />
      </FocusTrap>
    );

    const h = this.popoverTrackerHeight ? Math.min(this.popoverTrackerHeight, 150) : this.state.coords.height;

    const popover = (
      <Popover
        isOpen={this.state.showForm}
        body={form}
        onOuterAction={this.handleClosePopover}
        style={stylesPopover}
        className="Popover-booking"
        preferPlace="row"
        enterExitTransitionDurationMs={0}
        refreshIntervalMs={500}
      >
        <div
          className="popovertracker"
          style={{ position: 'absolute', width: poTrackerWidth, height: h }}
        />
      </Popover>
    );

    return this.chip(popover);
  }

  handleChipClick = () => {
    const { id, showForm, columnResource, resourceId } = this.props;

    if (id !== 'DRAGGER' && !showForm) {
      const sourceResourceId = columnResource?.id || resourceId;
      this.props.onChipClick(sourceResourceId);
    }
  };

  handleClosePopover = (ev) => {
    // Ignore if any modal is open - And dont preventDefault on the event..
    if (this.props.customerFormVisible || this.props.showModal) return;

    if (ev) {
      ev.preventDefault();
      // Dont invoke close logic if the clicked element was this chip..
      // This is to handle onOuterAction of the popover, as its no wrapping the  chip but rather a smaller div within the chip
      if (this.chipEl?.contains(ev.target)) return;
    }

    const state = { ...this.state.initState, showForm: false };
    this.setState(state);
    this.chipDragHandler?.onDragEndCancel();
    this.props.closeForm(this.state.initState);
  };

  handleConfirmMove = (options) => {
    return this.chipDragHandler.onDragEndConfirm(options);
  };

  confirmResize = (options) => {
    return this.chipDragHandler.onResizeEndConfirm(options);
  };

  confirmMovePopoverContent() {
    const { id, customer, copyOnPaste, routeParams } = this.props;

    return (
      <ConfirmMove
        bookingId={id}
        startTime={this.state.startTime}
        colIdx={this.state.coords.colIdx}
        copyOnPaste={copyOnPaste}
        routeParams={routeParams}
        customerEmail={customer?.email}
        customerPhoneNumber={customer?.phoneNumber}
        onClosePopover={this.handleClosePopover}
        onConfirmMove={this.handleConfirmMove}
        phoneMode={this.props.phoneMode}
      />
    );
  }

  confirmResizePopoverContent() {
    const duration = this.state.endTime.diff(this.state.startTime, 'minutes');
    return (
      <ConfirmResize
        duration={duration}
        onClosePopover={this.handleClosePopover}
        onConfirmResize={this.confirmResize}
        phoneMode={this.props.phoneMode}
        bookingId={this.props.id}
      />
    );
  }

  chip(popover) {
    const { id } = this.props;

    return (
      <div
        id={`chip${id}`}
        style={this.outerChipStyle()}
        className={this.classes()}
        ref={(chipEl) => { this.chipEl = chipEl; }}
        data-place="top"
        data-html
        data-delay-show={300}
        data-tip={this.tooltip()}
      >
        {popover}
        <div style={this.innerChipStyle()}>
          { this.reservationDuration() }
          { this.icons() }
          { this.timeHeader() }
          { this.content() }
          { this.resizeHandle() }
        </div>
        { this.afterTime() }
      </div>
    );
  }

  outerChipStyle() {
    const { isDimmed, hasDimmedBookings, resizable } = this.props;
    const { isDragging, isResizing } = this.state;

    const lineHeight = this.isSmall() ? 1.3 : 1.43;
    const { widthPct } = this.state.coords;
    const dragging = isDragging || isResizing ? styles.chips.dragging : {};
    const resizableStyle = !resizable ? { pointerEvents: 'none' } : {};
    const highlightedStyle = !isDimmed && hasDimmedBookings ? {
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.25)',
      zIndex: this.state.coords.zIndex + 100
    } : {};
    const dimmedStyle = isDimmed ? { opacity: 0.3 } : {};

    return {
      position: 'absolute',
      top: this.state.coords.top,
      left: `${this.state.coords.leftPct}%`,
      width: `${widthPct}%`,
      height: this.state.coords.height,
      zIndex: this.state.coords.zIndex,
      transition: 'opacity 0.2s',
      lineHeight,
      ...resizableStyle,
      ...highlightedStyle,
      ...dimmedStyle,
      ...dragging
    };
  }

  baseChipStatus() {
    const { type, status, channel, useWebBookingColor, timeSlot } = this.props;

    if (timeSlot) {
      return 'TimeSlot';
    }
    if (type === 'Reservation') {
      return type;
    }
    if (status === 'Booked' && useWebBookingColor && isWebBooking(channel)) {
      return 'Web';
    }
    return status || 'Booked';
  }

  innerChipStyle() {
    const {
      id, pending, status, pendingMove, highlight, highContrast,
      services, attributes, serviceColors, useWebBookingColor,
      isMultiResourceView, resourceColor, timeSlot
    } = this.props;
    const { isDragging, isResizing, showForm } = this.state;
    const isNew = id === 'DRAGGER';

    const visualStyles = getVisualStyles(highContrast);
    const hasColorway = attributes?.colorway;
    const hasStatus = !isNew && status && status !== 'Booked';
    const baseChipStatus = this.baseChipStatus();
    const chipStatus = hasColorway && !hasStatus ? attributes.colorway : baseChipStatus;

    const clipboardStyle = pendingMove ? { opacity: '0.5' } : {};
    const sdHeight = this.serviceChipHeight();
    const padding = this.isExtraSmall() ? '0 3px 0 3px' : '3px';
    const confirmState = this.confirmState();
    const shouldHighlight = isDragging || isResizing || showForm || highlight || confirmState.showConfirm;
    const pendingStyles = useWebBookingColor ? visualStyles.pendingWeb : visualStyles.pending;
    const dynamicStyles = !hasStatus && !timeSlot
      ? getDynamicColorStyles(attributes?.colorway, services, serviceColors, highContrast, shouldHighlight, isMultiResourceView && resourceColor)
      : null;

    const structuralStyle = {
      position: 'relative',
      height: sdHeight,
      padding,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };
    return {
      ...structuralStyle,
      ...styles.chips.base,
      ...clipboardStyle,
      ...visualStyles.statuses[chipStatus],
      ...(pending ? pendingStyles : {}),
      ...(shouldHighlight ? visualStyles.statuses[`${chipStatus}Drag`] : {}),
      ...dynamicStyles
    };
  }

  afterTime() {
    const {
      afterTime, gridProps, highContrast, status
    } = this.props;
    if (!afterTime || afterTime === 0) {
      return null;
    }

    if (status === 'Cancelled') {
      return null;
    }

    const atHeight = calcHeightFromMinutes(gridProps, afterTime, true);
    const className = highContrast ? 'after-time-hc' : 'after-time';
    const style = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: atHeight,
      zIndex: -1,
      overflow: 'hidden'
    };

    return (
      <div className={className} style={style}>
        <span>
          {afterTime} min
        </span>
      </div>
    );
  }

  classes() {
    const { undone, highlight } = this.props;

    return undone || highlight ? 'chip animated pulse-sm' : 'chip';
  }

  formattedTime(alwaysShowEndTime = false) {
    const showEndTime = this.state.coords.width > 150;

    const { afterTime } = this.props;
    const endTime = afterTime ? moment(this.state.endTime).subtract(afterTime, 'm') : this.state.endTime;

    return (
      <span>
        { this.state.startTime.format('LT') }
        { showEndTime || alwaysShowEndTime ? ` - ${endTime.format('LT')}` : '' }
      </span>
    );
  }

  timeHeader() {
    const { services, alwaysShowTime } = this.props;
    const hasMultipleServices = services && services.length > 1;
    const hideTime = this.isSmall()
      || (hasMultipleServices && !this.isLarge())
      || this.props.pending;

    return alwaysShowTime || !hideTime
      ? <div className="ch-header">{this.formattedTime()}</div>
      : null;
  }

  reservationDuration() {
    const { type } = this.props;
    const { startTime, endTime, coords } = this.state;

    if (type !== 'Reservation' || coords.width < 100) {
      return null;
    }

    return (
      <div className="pull-right ch-time">
        {getDiffString(endTime, startTime)}
      </div>
    );
  }

  icons() {
    const { status } = this.props;

    if (status === 'Cancelled' || this.isExtraSmall()) {
      return null;
    }

    return <ChipIcons {...this.props} />;
  }

  tooltip() {
    const { isDragging, isResizing } = this.state;
    const {
      embeddedInApp, showChipTooltip, bkfBkId, phoneMode, tabletMode,
      resources, reservation, description, services, id, scheduleEditMode
    } = this.props;
    const isNotDesktop = phoneMode || tabletMode || embeddedInApp;
    const isNew = id === 'DRAGGER';
    const classBooking = isClassBooking(this.props);

    if (isNew || isNotDesktop || isDragging || isResizing || bkfBkId || !showChipTooltip || scheduleEditMode) {
      return null;
    }

    const chipHeader = this.chipHeader();
    const serviceDescription = services?.count > 0
      ? services.map(({ name }) => name).join(', ')
      : description;

    return renderToStaticMarkup(
      <div className="chip-tooltip">
        <div className="time">{this.formattedTime(true)}</div>
        {!reservation && chipHeader && <div className="customer">{chipHeader}</div>}
        {!reservation && !classBooking && serviceDescription && <div className="services">{serviceDescription}</div>}
        <div className="resources">
          {resources.map(({ name }) => name).join(', ')}
        </div>
      </div>
    );
  }

  getRenderProps() {
    return pickAll(['customer', 'description', 'note', 'reservation', 'vehicleRegNo',
      'vehicleDescription', 'orgName', 'attributes', 'resourceName', 'reminders', 'type',
      'services', 'orgNo', 'custom'], this.props);
  }

  chipHeader() {
    const { customer, vehicleRegNo, orgName } = this.props;
    const { name, bookedAs } = customer || {};

    if (isClassBooking(this.props) && !isBookingNew(this.props)) {
      const { bookedSlots, maxSlots, services } = this.props;
      const serviceNames = services?.map(s => s.name).join(', ');
      return `${serviceNames} (${bookedSlots || 0}/${maxSlots || 0})`;
    }

    const customerText = orgName || bookedAs || name;
    const vehicleText = vehicleRegNo && formatVehicleRegNo(vehicleRegNo);

    return [vehicleText, customerText].filter(v => v).join(' - ');
  }

  content() {
    const {
      customer, description, note, reservation, reservationType, pending, status,
      vehicleDescription, attributes, services, alwaysShowServiceOnNewLine, seriesId
    } = this.props;

    const chipHeader = this.chipHeader();
    const serviceCount = services?.length || 0;
    const classBooking = isClassBooking(this.props);
    const useServiceBlocks = !classBooking && (serviceCount > 1 || alwaysShowServiceOnNewLine);
    const showFullSizeServices = useServiceBlocks && this.serviceChipHeight() > (20 + serviceCount * 22);
    const showDescription = !classBooking && (!showFullSizeServices || serviceCount === 0);
    const { chipTmplId } = attributes || {};
    const { customerNote } = customer || {};

    if (pending) {
      return <strong>{txt(msg.Pending)}</strong>;
    }
    if (status === 'Cancelled') {
      return (
        <div>
          <strong>{txt(msg.Cancelled)}</strong> <span className="text-small">{chipHeader}</span>
        </div>
      );
    }

    if (chipTmplId) {
      return (
        // eslint-disable-next-line react/no-danger
        <div className="ch-content" dangerouslySetInnerHTML={{ __html: renderTemplate(chipTmplId, this.getRenderProps()) }} />
      );
    }

    return (
      <div className="ch-content">
        {reservationType && this.reservationType()}
        {reservation && (reservationType ? note : <strong>{note}</strong>)}
        {!reservation && chipHeader && <strong>{chipHeader} </strong>}
        {!reservation && description && showDescription && <span className="text-small">{description}</span>}
        {!reservation && vehicleDescription && <div style={stylesVehicle}><small>{vehicleDescription}</small></div>}
        {!classBooking && useServiceBlocks && this.multipleServices(showFullSizeServices)}
        {!reservation && (note || customerNote) && <div className="ch-note">{note || customerNote}</div>}
        {seriesId && <div>Serie: {seriesId}</div>}
      </div>
    );
  }

  multipleServices(showFullSize) {
    const { services, serviceColors, highContrast } = this.props;
    const baseChipStatus = this.baseChipStatus();
    return services && (
      <div className={showFullSize ? 'ch-services' : 'ch-services minified'}>
        {services.map(service => {
          const style = getServiceBlockStyles(service, serviceColors, baseChipStatus, highContrast, showFullSize);
          return (
            <div key={service.id} style={style}>{showFullSize ? service.name : ''}</div>
          );
        })}
      </div>
    );
  }

  reservationType() {
    const { reservationType, highContrast } = this.props;
    const baseChipStatus = this.baseChipStatus();
    const style = getServiceBlockStyles(null, [], baseChipStatus, highContrast, true);

    return (
      <div className="ch-services">
        <div style={style}>{txt(msg[reservationType])}</div>
      </div>
    );
  }

  resizeHandle() {
    const scHeight = this.serviceChipHeight();
    const height = scHeight < 40 ? '25%' : 20;
    const handleStyle = {
      position: 'absolute',
      cursor: 'ns-resize',
      width: '100%',
      bottom: 0,
      left: 0,
      height
    };
    return (
      <div className="resize-handle" style={handleStyle}>
        {this.showResizeHandle() ? <span className="resize-handle-icon" /> : null}
      </div>
    );
  }

  showResizeHandle() {
    const {
      customer, description, note, vehicleRegNo, vehicleDescription, resizable
    } = this.props;
    const { bookedAs, name } = customer || {};

    if (!resizable) {
      return false;
    }

    // Calculate when to show resize handle based on length of chip content
    //
    const chars = [bookedAs || name, description, note, vehicleRegNo, vehicleDescription].join('').length;
    const limit = chars >= 20 ? 60 : 40;

    return this.serviceChipHeight() > limit;
  }

  isLarge() {
    return this.serviceChipHeight() > 100;
  }

  isSmall() {
    return this.serviceChipHeight() <= 55;
  }

  isExtraSmall() {
    return this.serviceChipHeight() <= 30;
  }

  serviceChipHeight() {
    const { afterTime } = this.props;
    let duration = this.state.endTime.diff(this.state.startTime, 'minutes');
    duration = afterTime ? duration - afterTime : duration;
    return calcHeightFromMinutes(this.props.gridProps, duration, true);
  }

  isCancelled() {
    return this.props.status === 'Cancelled';
  }

  hasContent() {
    const { customer, description, note, reservation } = this.props;

    return (reservation && !s.isBlank(note)) || !s.isBlank(customer?.name + description);
  }

  duration(precision) {
    return this.state.endTime.diff(this.state.startTime, precision);
  }

  undraggable() {
    if (this.chipDragHandler) {
      this.chipDragHandler.dispose();
      this.chipDragHandler = undefined;
    }
  }

  draggable() {
    if (!this.chipDragHandler) {
      this.chipDragHandler = new ChipDragHandler(this);
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { routeParams, embeddedInApp } = ownProps;

  return {
    onChipStateChange: (event) => {
      dispatch(updateBKFCoords(event, routeParams));
    },
    onChipMove: (event) => {
      return dispatch(moveBooking(event, routeParams));
    },
    onChipResize: (event) => {
      return dispatch(moveBooking(event, routeParams));
    },
    onChipClick: (sourceResourceId) => {
      const booking = {
        ...ownProps,
        sourceResourceId
      };
      if (embeddedInApp) {
        postWebkitMessage('showBookingForm', booking);
      } else {
        dispatch(loadBKFBooking(booking.id, booking.colIdx, sourceResourceId));
      }
    },
    updateHighlightedBookings: () => {
      dispatch(updateHighlightedBookings());
    },
    closeForm: (initState) => {
      dispatch(clearAndCloseBKF());
      dispatch(removeTempBooking());
      if (ownProps.id !== 'DRAGGER') {
        dispatch(bookingMoved(getMoveEvent(initState)));
      }
    }
  };
};

function getCustomValues(customFields) {
  if (!customFields || customFields.length === 0) {
    return null;
  }

  const values = {};
  customFields.forEach(({ key, value }) => {
    values[key] = value;
  });
  return values;
}

function getOverridesFromBkf(state, ownProps) {
  const { bkf } = state;
  const bkfService = bkf.get('service');
  const bkfCustomer = bkf.get('customer');
  const servicesArray = getBkfServiceArray(state);

  return {
    type: bkf.get('type'),
    status: bkf.get('status'),
    reservationType: bkf.get('reservationType'),
    afterTime: bkfService ? bkfService.afterTime : ownProps.afterTime,
    description: getDescription(bkfService && bkfService.name, servicesArray),
    company: bkf.get('vehicle'),
    vehicle: bkf.get('vehicle'),
    customer: bkfCustomer,
    bookedAs: bkfCustomer && bkfCustomer.customerId === ownProps.customerId ? ownProps.bookedAs : null,
    custom: bkf.get('customValues'),
    attributes: bkf.get('attributes'),
    services: servicesArray
  };
}

const mapStateToProps = (state, ownProps) => {
  const {
    bkf, resourcesById, cf: customerForm, gridViewState, locationConfig,
    locationFeatures, bookingsClipboard, mainViewState
  } = state;

  // If there is a booking in the bkf that matches this chip, then render popover open
  //
  const bkfBkId = bkf.get('id');
  const bkfColIdx = bkf.get('colIdx');
  const showForm = !!bkfBkId && bkfBkId === ownProps.id && ownProps.colIdx === bkfColIdx;
  const override = showForm ? getOverridesFromBkf(state, ownProps) : {};
  const clip = bookingsClipboard.get(ownProps.id);
  const pendingMove = (clip !== undefined && !clip.copyOnPaste);
  const dimmedBookingIds = getDimmedBookingIds(state);
  const scheduleEditMode = gridViewState.get('scheduleEditMode');
  const isDragger = ownProps.id === NEW_BOOKING;
  const customer = getPrimaryCustomer(ownProps);
  const company = getCompanyCustomer(ownProps);
  const vehicle = getVehicleCustomer(ownProps);
  const pending = customer?.status === 'Pending';

  return {
    bkfBkId,
    showForm,
    seriesId: ownProps.bookingSeries?.seriesId,
    customerFormVisible: customerForm.get('formVisible'),
    showModal: bkf.get('showModal'),
    pending,
    pendingMove,
    resourcesById,
    scheduleEditMode,
    customer,
    orgNo: company?.officialIdNo,
    orgName: company?.name,
    vehicleRegNo: vehicle?.officialIdNo,
    vehicleDescription: vehicle?.name,
    channel: customer?.channel || 'Cal',
    status: ownProps.status || 'Booked',
    attributes: ownProps.attributes,
    description: getDescription(ownProps.description, ownProps.services),
    resizable: isDragger || !(pending || pendingMove) && !ownProps.timeSlot && !scheduleEditMode,
    highContrast: gridViewState.get('highContrast'),
    showChipTooltip: gridViewState.get('showChipTooltip'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    confirmMoveEnabled: confirmMoveBooking(locationConfig, ownProps),
    showAssociatedResource: locationConfig.get(calendar.showAssociatedResource),
    showNotesIndicator: locationConfig.get(calendar.showNotesIndicator),
    useWebBookingColor: locationConfig.get(calendar.useWebBookingColor),
    showPaidBookingBadge: locationConfig.get(calendar.showPaidBookingBadge),
    alwaysShowTime: locationConfig.get(calendar.alwaysShowTime),
    alwaysShowServiceOnNewLine: locationConfig.get(calendar.alwaysShowServiceOnNewLine),
    highlight: ownProps.id === ownProps.routeParams.bookingId,
    hasDimmedBookings: dimmedBookingIds.length > 0,
    isDimmed: dimmedBookingIds.includes(ownProps.id),
    features: locationFeatures,
    deviceType: mainViewState.get('deviceType'),
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    custom: getCustomValues(ownProps.customFields),
    serviceColors: getServiceColors(state),
    isMultiResourceView: getIsMultiResourceView(state, ownProps),
    columnResource: getResourceFromColIdx(state, ownProps),
    ...override
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(Chip);
