import moment from 'moment';
import interact from 'interactjs';
import ReactDOM from 'react-dom';
import { toggleDragging } from '@State/view-actions';
import { getElementHeight } from '@Utils/dimensions';
import CoordHelper from './grid-coord-helper';

export class ChipDragHandler {
  // NOTE! the 'this' is bound to the chip's 'this'. So all of the eventhandler code essentially executes as if
  // it was part of the chip class. This is to make sure we don't act upon stale state or properties

  constructor(clipItem) {
    this.clipItem = clipItem;
    this.cHelper = new CoordHelper({
      gridProps: clipItem.props.gridProps
    });

    this.handlers = {
      dragStart: this.onDragStart.bind(this),
      dragMove: this.onDragMove.bind(this),
      dragEnd: this.onDragEnd.bind(this),
      hold: this.onHold.bind(this)
    };

    this.makeItDraggable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.cHelper = new CoordHelper(nextProps);
  }

  get coordsHelper() {
    return this.cHelper;
  }

  get state() {
    return this.chip.state;
  }

  get props() {
    return this.chip.props;
  }

  setState(state) {
    this.chip.setState(state);
  }

  dispose() {
    if (this.interactable) {
      this.interactable.unset();
    }
  }

  makeItDraggable() {
    const manualStart = interact.supportsTouch() && (window.navigator.userAgent.indexOf('Windows NT 10') === -1);
    const chipEl = ReactDOM.findDOMNode(this.clipItem);
    const evOwEl = document.getElementById('eventOwner');

    this.interacting = false;
    this.interactable = interact(chipEl)
      .draggable({
        manualStart,
        inertia: false
      })
      .pointerEvents({
        holdDuration: 150
      })
      .preventDefault('never')
      .origin(evOwEl)
      .styleCursor(true)
      .on('dragstart', this.handlers.dragStart)
      .on('dragmove', this.handlers.dragMove)
      .on('dragend', this.handlers.dragEnd)
      .on('hold', this.handlers.hold)
      .on('tap', this.onTap)
      .on('move', (event) => {
        if (event.interaction.interacting()) {
          // This is supposedly preventing the div to scroll while moving/marking.
          event.preventDefault();
        }
      })
      .on('touchmove', (event) => {
        if (this.interacting) {
          // This is supposedly preventing the div to scroll while moving/marking.
          event.preventDefault();
        }
      });
  }

  duration(precision) {
    return this.clipItem.props.booking.endTime.diff(this.clipItem.props.booking.startTime, precision);
  }

  chipMounted = (chip) => {
    if (chip == null) {
      this.chip = null;
      return;
    }
    this.chip = chip;
    this.chip.chipDragHandler.onDragEndConfirm = this.onDragEndConfirm;
    this.chip.chipDragHandler.onDragEndCancel = this.onDragEndCancel;
  };

  clipboardDraggerMounted = (dragger) => {
    if (dragger == null) {
      this.clipDragger = null;
      return;
    }

    this.clipDragger = dragger;
  };

  onTap = (event) => {
    event.stopPropagation();
    try {
      // If tap was on the close button.
      if (event.target.classList.contains('tap-close')) {
        this.clipItem.cancelPasteBooking(event);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  onHold(event) {
    const { interaction } = event;
    try {
      if (!interaction.interacting()) {
        interaction.start({ name: 'drag' },
          event.interactable,
          event.currentTarget);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onDragStart(event) {
    event.stopPropagation(); // prevent grid event
    try {
      toggleDragging(true);
      this.clipItem.setState({
        isDragging: true
      });

      // These coordinates must be the equivalent of screenX/Y rather than the clientX/Y in relation
      // to the origin (#eventowner), which is what interactjs gives. It seems interactjs provides this in the property:
      // _interaction.coords.start.client.y/x for now. But its undocumented and may break in a future version.
      // as has happened twice before
      //
      const clientY = event._interaction.coords.start.client.y,
        clientX = event._interaction.coords.start.client.x;

      const chipEl = ReactDOM.findDOMNode(this.clipItem);
      const rect = chipEl.getBoundingClientRect();
      this.clipItem.props.onClosePopover();

      const { type, status } = this.clipItem.props.booking;
      this.clipItem.props.onAddClipboardDragger({
        refCallback: this.clipboardDraggerMounted,
        initialTop: Math.round(clientY - (rect.height / 2)),
        initialLeft: Math.round(clientX - (rect.width / 2)),
        label: this.clipItem.label(),
        type,
        status
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  createDragger(event) {
    const ch = this.coordsHelper;
    const newX = ch.withXMargin(ch.snapToGridX(event));
    const diff = 0;
    const newY = ch.withYMargin(ch.snapToGridY(event.clientY - diff));
    const newStartTime = ch.timeFor(newX, newY);
    const newEndTime = moment(newStartTime).add(this.duration(), 'ms');
    const newColIdx = ch.columnIdx(event);

    const dragger = {
      ...this.clipItem.props.booking,
      startTime: newStartTime,
      endTime: newEndTime,
      colIdx: newColIdx,
      refCallback: this.chipMounted
    };

    return dragger;
  }

  isPointerOutsideGrid(event) {
    const clientY = event._interaction.coords.cur.client.y,
      clientX = event._interaction.coords.cur.client.x;
      // Fix: Lookup gridcontainer and clip item on drag start and cache the values instead.
      //
    const yBoundsRect = document.getElementById('gridcontainer').getBoundingClientRect(),
      xBoundsRect = document.getElementById('eventOwner').getBoundingClientRect();

    // Calculate the offset if calendar is embedded
    //
    const ti = parseInt(this.clipItem.props.gridProps.embedTopIndent) || 0;
    const embedded = this.clipItem.props.gridProps.embedMode === 'calendar';
    const headerContainerHeight = getElementHeight('#gridheadercontainer');
    const offset = embedded ? headerContainerHeight + ti : 0;

    return clientY < yBoundsRect.top + offset
      || clientY >= yBoundsRect.bottom
      || clientX < xBoundsRect.left
      || clientX >= xBoundsRect.right;
  }

  onDragMove(event) {
    this.interacting = true;
    event.stopPropagation(); // prevent grid event
    try {
      const pointerOutsideGrid = this.isPointerOutsideGrid(event);

      if (this.draggerAdded === true && pointerOutsideGrid) {
        this.draggerAdded = false;
        this.clipDragger.setState({
          hide: false
        });

        this.clipItem.props.onRemoveChipDragger();
      } else if (this.draggerAdded !== true && !pointerOutsideGrid) {
        this.draggerAdded = true;
        this.clipDragger.setState({
          hide: true
        });

        const dragger = this.createDragger(event);
        this.clipItem.props.onAddChipDragger(dragger);
      } else if (this.clipDragger != null) {
        const chipEl = ReactDOM.findDOMNode(this.clipItem);
        const rect = chipEl.getBoundingClientRect();

        const tY = event._interaction.coords.cur.client.y,
          tX = event._interaction.coords.cur.client.x;

        this.clipDragger.setState({
          top: Math.round(tY - (rect.height / 2)),
          left: Math.round(tX - (rect.width / 2)),
          isDragging: true,
          chipHeight: Math.round(rect.height),
          chipWidth: Math.round(rect.width)
        });
      }

      if (this.chip == null) { return; }

      const chipEl = ReactDOM.findDOMNode(this.chip);
      const rect = chipEl.getBoundingClientRect();

      const diff = this.state.dragChipTopDiff;
      const ch = this.coordsHelper;
      const newColIdx = ch.columnIdx(event);
      const newY = ch.withYMargin(ch.snapToGridY(event.clientY - diff));
      const newX = ch.withXMargin(ch.snapToGridX(event));
      const leftPct = ch.leftPct(newX);

      // const newStartTime = ch.timeFor(newX, newY - (rect.height / 2));
      const newStartTime = ch.timeFor(newX, newY);
      const newEndTime = moment(newStartTime).add(this.chip.duration(), 'ms');
      const widthPct = ch.widthPct(ch.draggingWidth);

      const coords = {
        ...this.state.coords,
        top: newY,
        leftPct,
        widthPct,
        colIdx: newColIdx
      };

      this.setState({
        coords,
        isDragging: true,
        startTime: newStartTime,
        endTime: newEndTime
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      this.onDragEndCancel();
    }
  }

  onDragEnd(event) {
    event.stopPropagation(); // prevent grid event

    if (this.chip) {
      const newBooking = {
        ...this.clipItem.props.booking,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        colIdx: this.state.coords.colIdx,
        refCallback: null
      };
      this.chip.chipDragHandler.onDragEnd(event, newBooking);
    } else {
      this.onDragEndCancel();
    }
  }

  onDragEndConfirm = (confirmOptions) => {
    try {
      const newBooking = {
        ...this.clipItem.props.booking,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        colIdx: this.state.coords.colIdx,
        refCallback: null,
        ...confirmOptions
      };
      this.clipItem.props.onRemoveClipboardDragger();
      return this.clipItem.props.onPasteBooking(newBooking);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  onDragEndCancel = () => {
    toggleDragging(false);
    this.draggerAdded = false;
    this.clipDragger.setState({ hide: false });
    this.clipItem.props.onRemoveChipDragger();
    this.clipItem.props.onRemoveClipboardDragger();
  };
}
