import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import SelectField from '@Components/inputs/select-field';
import Button from '@Components/ui/button';
import SubmitButton from '@Components/ui/submit-button';
import { getRruleDays } from './recurring-util';

const BookingRecurringForm = ({
  handleSubmit = null, onClose = null, submitting = false,
  repeat = 'WEEKLY', days = [], end = 'UNTIL'
}) => {
  const dispatch = useDispatch();
  const rruleDays = useMemo(getRruleDays, []);

  const toggleDay = (day) => {
    const newDays = days.includes(day)
      ? days.filter(d => d !== day)
      : [...days, day];
    dispatch(change('bkf-recurring', 'days', newDays));
  };

  const handleDayClick = (ev, day) => {
    ev.preventDefault();
    toggleDay(day);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row tight">
        <Field
          name="repeat"
          label="Repetera"
          className="col-xs-6"
          component={SelectField}
          autoFocus
        >
          <option value="WEEKLY">Veckovis</option>
          <option value="DAILY">Dagligen</option>
        </Field>
        <div className="form-group col-xs-6">
          <label className="control-label">Intervall</label>
          <Field
            name="interval"
            component="input"
            className="form-control"
          />
        </div>
      </div>
      {repeat === 'WEEKLY' && (
        <div className="row tight">
          <div className="form-group col-xs-12">
            <label className="control-label">Veckodagar</label>
            <div className="btn-group">
              {rruleDays.map(({ day, name }) => (
                <button
                  key={day}
                  className={days.includes(day) ? 'btn btn-primary' : 'btn btn-default'}
                  onClick={(ev) => handleDayClick(ev, day)}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="row tight">
        <Field
          name="end"
          label="Slutar"
          className="col-xs-6"
          component={SelectField}
        >
          <option value="UNTIL">Vid datum</option>
          <option value="COUNT">Efter antal</option>
        </Field>
        <div className="form-group col-xs-6">
          <label className="control-label">&nbsp;</label>
          {end === 'UNTIL' && (
            <Field
              name="until"
              component="input"
              type="date"
              className="form-control"
            />
          )}
          {end === 'COUNT' && (
            <Field
              name="count"
              component="input"
              className="form-control"
              required
            />
          )}
        </div>
      </div>
      {handleSubmit && onClose && (
        <div className="text-right mt2">
          <Button small gray marginRight onClick={onClose}>Avbryt</Button>
          <SubmitButton submitting={submitting} onClick={handleSubmit}>Spara</SubmitButton>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    ...getFormValues('bkf-recurring')(state)
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'bkf-recurring',
  destroyOnUnmount: false
})(BookingRecurringForm));
