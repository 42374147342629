import React from 'react';
import { isBookingRecurring } from '@Utils/booking-util';

const getVerb = (action) => {
  switch (action) {
    case 'move':
      return 'Flytta';
    case 'copy':
      return 'Kopiera';
    default:
      return 'Ändra';
  }
};

const ConfirmChangeSeries = ({ action, booking, series, updateSeries, onChange }) => {
  const { published, bookingIds } = series;
  const isRecurring = isBookingRecurring(booking);
  const index = bookingIds.indexOf(booking.id);
  const isFirst = index === 0;
  const isLast = index === bookingIds.length - 1;
  const verb = getVerb(action);

  if (!isRecurring) {
    return null;
  }

  if (published) {
    return (
      <p className="text-info mb2">
        <i className="fa fa-exclamation-circle mr1" />
        Bokningen är del av en serie som är publicerad.
        Du kan endast ändra den aktuella bokningen.
      </p>
    );
  }

  return (
    <>
      <div className="radio">
        <label>
          <input type="radio" value="Single" checked={updateSeries === 'Single'} onChange={onChange} />
          {verb} endast denna bokning
        </label>
      </div>
      {!isFirst && !isLast && action !== 'copy' && (
        <div className="radio">
          <label>
            <input type="radio" value="Future" checked={updateSeries === 'Future'} onChange={onChange} />
            {verb} framtida bokningar
          </label>
        </div>
      )}
      <div className="radio">
        <label>
          <input type="radio" value="All" checked={updateSeries === 'All'} onChange={onChange} />
          {verb} alla bokningar
        </label>
      </div>
    </>
  );
};

export default ConfirmChangeSeries;
